class TypeaheadControl {
  constructor($el) {
    this.$el = $el;

    this.init();
  }

  init() {
    this.initConditionalValues();
    this.initTargetInputs();
    this.initSelect();
  }

  initConditionalValues() {
    this.conditionalValues = this.$el.dataset
      .typeaheadDisableConditionalValues.split(',');
  }

  initTargetInputs() {
    const targetInputIds = this.$el.dataset
      .typeaheadDisableInputIds.split(',');

    this.$targetInputs = document.querySelectorAll(
      targetInputIds.map(v => `#${v}`).join(', '),
    );
  }

  initSelect() {
    this.$el.addEventListener('change', (event) => {
      if (this.conditionalValues.includes(event.currentTarget.value)) {
        this.$targetInputs.forEach(($input) => {
          $input.removeAttribute('data-typeahead-disabled');
        });
      } else {
        this.$targetInputs.forEach(($input) => {
          $input.setAttribute('data-typeahead-disabled', 'true');
        });
      }
    });
  }
}

// Initialize TypeaheadControl
document.querySelectorAll('[data-typeahead-control]').forEach(
  $el => new TypeaheadControl($el),
);
