import TextInput from '../../atoms/textinput/textinput';
import OfficeList from '../../molecules/office-list/office-list';
import TabBar from '../../molecules/tab-bar/tab-bar';

const apiKey = 'AIzaSyDJEKOs39HLeLIbZ_AmC0tZPQYtS24GFVw';
const filterApiUrl = '/api/office-distances.json';
const apiOptions = {
  method: 'post',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
};

export default class OfficeMap {
  /**
   * @var {{name: string}[]} BranchOffices
   */
  static BranchOffices = [];

  static DefaultOptions = {
    classes: {
      element: 'js-office-map',
      initializedModifier: 'js-office-map--initialized',
      zipInput: 'office-map__zip-search > .textinput',
      tabbar: 'office-map .tab-bar',
      list: 'office-map__list',
      map: 'office-map__map',
      form: 'office-map__left',
      markerTemplate: 'office-map__marker-template',
      consentMessage: 'office-map__consent',
      consentCheckbox: 'office-map__consent-checkbox',
      consentButton: 'office-map__consent-button',
    },
    mapOptions: {
      center: { lat: 51.2247467, lng: 10.1579036 },
      zoom: 6,
    },
    defaultFilterDistance: 200e3,
  };

  /**
   * Create a new instance of an office map
   * @param {HTMLElement} $element The dom element to be enhanced
   * @param {{
   *  classes: {
   *    element: string,
   *    initializedModifier: string
   *    zipInput: string,
   *    tabbar: string,
   *    list: string,
   *    map: string,
   *    form: string
   *    markerTemplate: string,
   *    consentMessage: string,
   *    consentButton: string,
   *    consentCheckbox: string
   *  }
   *  officeApiUrl: string,
   *  filterApiUrl: number,
   *  defaultFilterDistance: number
   * }} options Additional options to the component
   */
  constructor($element, options = {}) {
    this.$element = $element;
    this.options = {
      ...OfficeMap.DefaultOptions,
      ...options,
      officeApiUrl: this.$element.dataset.officesApi || '{}',
      filterApiUrl: this.$element.dataset.filterApi || filterApiUrl,
      focusZoom: parseFloat(this.$element.dataset.mapZ || 13),
      defaultFilterDistance: parseFloat(this.$element.dataset.filterDistance || '0'),
    };

    this.pois = [];

    this.init();
  }

  /**
   * Initializes the component
   */
  async init() {
    const { Map, InfoWindow } = google.maps;

    this.$zipInput = this.$element.querySelector(`.${this.options.classes.zipInput}`);
    this.$tabbar = this.$element.querySelector(`.${this.options.classes.tabbar}`);
    this.$list = this.$element.querySelector(`.${this.options.classes.list}`);
    this.$map = this.$element.querySelector(`.${this.options.classes.map}`);
    this.$markerTemplate = this.$element.querySelector(`.${this.options.classes.markerTemplate}`);

    this.zipInput = new TextInput(this.$zipInput, {
      onchange: this.filterOffices.bind(this),
    });
    this.tabbar = new TabBar(this.$tabbar);
    this.list = new OfficeList(this.$list);

    this.googleMap = new Map(this.$map, this.options.mapOptions);
    this.infoWindow = new InfoWindow();

    this.initBranchOffices();
    this.$element.classList.add(this.options.classes.initializedModifier);
  }

  /**
   * Initializes the list of branch offices from the api.
   */
  async initBranchOffices() {
    const { onMarkerClick, onItemClick, onItemClose } = this;
    const branchOffices = JSON.parse(this.options.officeApiUrl || '{}');

    this.list.clearItems();

    branchOffices.forEach(
      /**
       * @param {{
       *  uid: number,
       *  name: string,
       *  street: string,
       *  zip: number,
       *  city: string,
       *  times: string,
       *  phone: string,
       *  phone_time: string,
       *  fax: string,
       *  email: string,
       *  latitude: string|number,
       *  longitude: string|number,
       *  sickPay: string,
       *  care: string
       * }} office An office item got from the api
       */
      (office) => {
        this.list.addItem(OfficeList.CreateItem(office), {
          onopen: onItemClick.bind(this),
          onclose: onItemClose.bind(this),
        });
        this.CreatePoi({
          uid: office.uid.toString(),
          position: {
            lat: parseFloat(office.latitude),
            lng: parseFloat(office.longitude),
          },
          title: office.name,
          content: this.$markerTemplate.innerHTML
            .replaceAll('%OFFICE_NAME%', office.name || '')
            .replaceAll('%OFFICE_STREET%', office.street || '')
            .replaceAll('%OFFICE_ZIP%', office.zip || '')
            .replaceAll('%OFFICE_CITY%', office.city || '')
            .replaceAll('%OFFICE_TIMES%', office.times || '')
            .replaceAll('%OFFICE_PHONE_TIME%', office.phone_time || ''),
          onclick: onMarkerClick.bind(this),
        });
      },
    );
  }

  /**
   * Creates a new Google Maps Marker
   * @param {{
   *  uid: string,
   *  position: { lat: number, lng: number },
   *  title: string,
   *  content: string,
   *  onclick?: (uid: string) => Promise<void>
   * }} newPoi
   */
  async CreatePoi(newPoi = {}) {
    const { googleMap } = this;
    const { Marker } = google.maps;
    const {
      uid, position, title, content, onclick,
    } = newPoi;

    const marker = new Marker({
      position,
      title,
      map: googleMap,
    });

    marker.addListener('click', async () => {
      if (onclick) {
        await onclick(uid);
      }
      this.infoWindow.setContent(content);
      this.infoWindow.open({ anchor: marker, map: googleMap });
    });

    this.pois.push({ uid, marker });
  }

  showPoi(poi) {
    poi.marker.setMap(this.googleMap);
  }

  hidePoi(poi) {
    poi.marker.setMap(null);
  }

  deletePoi(poi) {
    this.hideMarker(poi);
    this.pois.splice(this.pois.indexOf(poi), 1);
  }

  /**
   * Apply the filter to the items
   */
  async filterOffices() {
    if (this.zipInput.value.length < 5) {
      // Reset filter
      this.list.filter(() => true);

      // Reset sorting
      this.list.sortItems((a, b) => a.officeItem.uid - b.officeItem.uid);

      // Reset filter distances
      // eslint-disable-next-line
      this.list.listItems.forEach((item) => { item.officeItem.Distance = 0; });

      // Reset all pois
      this.pois.forEach(poi => this.showPoi(poi));
      return;
    }
    /**
     * @type { {distance:number,city:number}[] }
     */
    const distances = await fetch(this.options.filterApiUrl, {
      ...apiOptions,
      body: `zip=${this.zipInput.value}`,
    })
      .then(response => response.json());

    const { passed, failed } = this.list.filter(
      office => distances.find(
        /**
         * @param {{distance: number,city: number}} dist
         * @returns
         */
        dist => dist.city.toString() === office.uid
          && dist.distance < this.options.defaultFilterDistance,
      ),
    );

    passed.forEach(
      (item) => {
        const { officeItem } = item;
        const { distance } = distances
          .find(dist => `${dist.city}` === officeItem.uid);

        officeItem.Distance = Math.round(distance / 1000.0);
      },
    );

    failed.forEach((listItem) => {
      // eslint-disable-next-line
      listItem.officeItem.Distance = 0;
    });

    // Hide all pois which did not pass the filter
    this.pois
      .filter(poi => failed
        .find(item => item.officeItem.uid === poi.uid))
      .forEach(poi => this.hidePoi(poi));

    // Show all pois which passed the filter
    this.pois
      .filter(poi => passed
        .find(item => item.officeItem.uid === poi.uid))
      .forEach(poi => this.showPoi(poi));

    // Sort the list for distances to the search  query
    this.list.sortItems((a, b) => {
      const distA = a.officeItem.Distance || Infinity;
      const distB = b.officeItem.Distance || Infinity;
      return distA - distB;
    });
  }

  /**
   * @param {string} uid The offices uid
   */
  onMarkerClick(uid) {
    // eslint-ignore-next-line
    this.list.listItems
      .filter(listItem => listItem.officeItem.uid === uid.toString())
      .forEach(listItem => listItem.officeItem.open());
  }

  /**
   * React to click on an office list item
   * @param {OfficeItem} officeItem The office item which was clicked on.
   */
  onItemClick(officeItem) {
    const { latitude, longitude } = officeItem.options;
    // console.log(latitude, longitude);
    this.googleMap.setCenter({
      lat: latitude,
      lng: longitude,
    });
    this.googleMap.setZoom(this.options.focusZoom);
  }

  /**
   * React to when an office item is closed
   */
  onItemClose() {
    const { center, zoom } = OfficeMap.DefaultOptions.mapOptions;
    this.googleMap.setCenter(center);
    this.googleMap.setZoom(zoom);
    this.infoWindow.close();
  }

  static async InitGoogleLibrary() {
    const $script = document.createElement('script');
    $script.defer = true;
    $script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=OfficeMapsInit`;
    document.body.append($script);
  }

  static async InitializeAll() {
    const { element, initializedModifier, consentMessage } = OfficeMap.DefaultOptions.classes;

    document.querySelectorAll(`.${element}:not(.${initializedModifier})`)
      .forEach((/** @type {HTMLElement} */ $officeMap) => {
        const $consentMessage = $officeMap.querySelector(`.${consentMessage}`);
        $consentMessage.parentElement.removeChild($consentMessage);
        return new OfficeMap($officeMap);
      });
  }
}

window.addEventListener('load', () => {
  const { consentMessage, consentCheckbox, consentButton } = OfficeMap.DefaultOptions.classes;
  /** @type {HTMLElement} */
  const $consentMessage = document.querySelector(`.${consentMessage}`);

  /** @type {HTMLButtonElement} */
  const $consentButton = $consentMessage.querySelector(`.${consentButton}`);

  /** @type {HTMLInputElement} */
  const $consentCheckbox = $consentMessage.querySelector(`.${consentCheckbox}`);

  $consentCheckbox.addEventListener('change', () => {
    $consentButton.disabled = !$consentCheckbox.checked;
  });

  $consentButton.addEventListener('click', () => {
    if ($consentCheckbox.checked) {
      OfficeMap.InitGoogleLibrary();
    }
  });

  window.OfficeMapsInit = () => {
    OfficeMap.InitializeAll();
  };
});
