import h from 'hyperscript';

// Used selectors
const SELECTORS = {
  element: '.search-bar',
  container: '.search-bar__suggestions',
  containerVisible: 'search-bar__suggestions--visible',
  input: '.search-bar__input',
  list: '.search-bar__list',
  reset: '.search-bar__reset',
  listItem: 'li.search-bar__list-item',
  highlight: 'em.search-bar__highlight',
};

// Defines HeaderSearch
class HeaderSearch {
  constructor($el) {
    this.$el = $el;
    this.$input = $el.querySelector(SELECTORS.input);
    this.$suggestionsContainer = $el.querySelector(SELECTORS.container);
    this.$suggestionsList = $el.querySelector(SELECTORS.list);
    this.$resetButton = $el.querySelector(SELECTORS.reset);
    this.dataSource = this.$el.dataset.source;

    this.initBindings();
  }

  initBindings() {
    this.$input.addEventListener('input', this.fetchSuggestions.bind(this));
    this.$resetButton.addEventListener('click', this.clearSuggestions.bind(this));
  }

  fetchSuggestions() {
    this.$resetButton.setAttribute('aria-hidden', this.$input.value === '');

    if (this.$input.value === '') {
      this.clearSuggestions();
    } else {
      // Use JSON or build url with current user input
      const fetchUrl = this.dataSource.includes('.json') ? this.dataSource : `${this.dataSource}/${this.$input.value}`;

      this.get(fetchUrl, this.updateSuggestions.bind(this));
    }
  }

  updateSuggestions(data) {
    this.$suggestionsContainer.setAttribute('aria-hidden', 'false');

    if (data.length) {
      this.suggestions = data.map((item) => {
        const highlight = item.value.replace(
          new RegExp(this.$input.value, 'i'),
          h(SELECTORS.highlight, this.$input.value).outerHTML,
        );

        return h(SELECTORS.listItem,
          h(`a${SELECTORS.list}-item-link`, { href: item.url, innerHTML: highlight }));
      });

      this.$suggestionsList.innerHTML = h(`ul${SELECTORS.list}-inner`, this.suggestions).outerHTML;
    } else {
      this.clearSuggestions();
    }
  }

  clearSuggestions() {
    this.$resetButton.setAttribute('aria-hidden', 'true');
    this.$suggestionsContainer.setAttribute('aria-hidden', 'true');
    this.$suggestionsList.innerHTML = '';
  }

  get(url, successCallback) {
    this.req = new XMLHttpRequest();
    this.req.open('get', url);
    this.req.onload = () => {
      if (this.req.status === 200) successCallback(JSON.parse(this.req.response));
      else Error(this.req.statusText);
    };

    this.req.send();
  }
}

// Initialize HeaderSearch
document
  .querySelectorAll(SELECTORS.element)
  .forEach($el => new HeaderSearch($el));
