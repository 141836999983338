export default class TabBar {
  /**
   *s
   * @param {HTMLElement} $element The Dom element to be enhanced
   * @param {{
   *  classes: {
   *    tab: string
   *    tabcontent: string
   *    tabopen: string
   *    tabselected: string
   *  }
   * }} options
   */
  constructor($element, options = { }) {
    this.$element = $element;
    this.options = {
      ...options,
      classes: {
        tab: 'tab-bar__tab',
        tabcontent: 'tab-bar__tab-content',
        tabopen: 'tab-bar__tab--open',
        tabselected: 'tab-bar__item--selected',
        ...(options.classes || {}),
      },
    };

    this.init();
    // console.log(this);
  }

  init() {
    const $tabs = Array
      .from(this.$element.querySelectorAll(`.${this.options.classes.tab}`));

    this.tabs = [];
    $tabs.forEach(($tab) => {
      const targetId = $tab.getAttribute('aria-controls');
      const $target = targetId
        ? document.getElementById(targetId)
        : null;

      if ($target) {
        const tab = { $trigger: $tab, $target };
        tab.$target.classList.add(this.options.classes.tabcontent);

        this.tabs.push(tab);
        $tab.addEventListener('click', () => {
          this.tabs.forEach(t => this.closeTab(t));
          this.openTab(tab);
        });
      }
    });

    this.openTab(this.tabs[0]);
  }

  /**
   * Open a tab given by its id.
   * @param { {$trigger: Element, $target: Element} } tab The tab to be opened
   */
  openTab(tab) {
    tab.$trigger.parentElement.classList.add(this.options.classes.tabselected);
    tab.$trigger.classList.add(this.options.classes.tabopen);
    tab.$target.setAttribute('aria-expanded', true);
  }

  /**
   * Close a tab given by its id.
   * @param { { $trigger: Element, $target: Element } } tab The tab to be closed
   */
  closeTab(tab) {
    tab.$trigger.parentElement.classList.remove(this.options.classes.tabselected);
    tab.$target.removeAttribute('aria-expanded');
    tab.$trigger.classList.remove(this.options.classes.tabopen);
  }

  static Initialize() {
    document.querySelectorAll('.js-tab-bar').forEach(
      $tabbar => new TabBar($tabbar),
    );
  }
}

TabBar.Initialize();
