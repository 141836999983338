import chatBotOpen from './quicklinks-actions';

export default class QuickLinks {
  static DefaultOptions = {
    classes: {
      element: 'js-quicklinks',
      initializedModifier: 'quicklinks--initialized',
      showButton: 'quicklinks__button--show',
      closeButton: 'quicklinks__button--close',
      container: 'quicklinks__container',
      containerOpenModifier: 'quicklinks__container--open',
      containerHiddenModifier: 'quicklinks__container--hidden',
      item: 'quicklinks__item',
      itemOpenModifier: 'quicklinks__item--open',
    },
  };

  /** @type {{[index: string]: Function}} */
  static QuicklinkActions = {};

  /** @type {HTMLElement} */
  $element;

  /** @type {HTMLElement} */
  $container;

  /** @type {number} */
  timer;

  /** @type {number} */
  scrollOffset;

  /** @type {boolean} */
  desktopEnabled;

  /** @type {boolean} */
  showAlways;

  /**
   * Creates a new instance of a quicklinks component
   * @param {HTMLElement} $element The dom element the component is build from
   * @param {{
   *  classes: {
   *    element: string,
   *    initializedModifier: string,
   *    showButton?: string,
   *    closeButton?: string,
   *    container?: string,
   *    containerOpenModifier?: string,
   *    containerHiddenModifier?: string
   *    item?: string,
   *    itemOpenModifier?: string
   *  }
   * }} options Options to the component
   */
  constructor($element, options = {}) {
    this.$element = $element;
    this.options = {
      ...QuickLinks.DefaultOptions,
      ...options,
    };

    this.init()
      .then(() => {
        this.$element.classList.add(this.options.classes.initializedModifier);
      });
  }

  async init() {
    this.scrollOffset = parseFloat(this.$element.dataset.scrollOffset || '300');
    this.showAlways = this.$element.hasAttribute('data-show-always');
    this.$showButton = this.$element.querySelector(`.${this.options.classes.showButton}`);
    this.$closeButton = this.$element.querySelector(`.${this.options.classes.closeButton}`);
    this.$container = this.$element.querySelector(`.${this.options.classes.container}`);

    if (this.$showButton && this.$container) {
      this.$showButton.addEventListener('click', this.openMenu.bind(this));
    }

    if (this.$closeButton && this.$container) {
      this.$closeButton.addEventListener('click', this.closeMenu.bind(this));
    }

    this.$element.querySelectorAll('[aria-controls]').forEach(
      $controller => $controller.addEventListener('click', this.toggle.bind(this)),
    );

    window.addEventListener('click', (e) => {
      const $target = e.target;
      const $closest = $target.closest(`.${this.options.classes.itemOpenModifier}`);

      if (!$closest) {
        this.$element.querySelectorAll(`.${this.options.classes.itemOpenModifier}`).forEach(
          $item => this.closeItem($item),
        );
      }
    });

    const mediaQuery = 'screen and (min-width: 786px)';
    const mq = window.matchMedia(mediaQuery);
    mq.addEventListener('change', this.onmediachange.bind(this));
    this.desktopEnabled = mq.matches;

    window.addEventListener('scroll', this.onscroll.bind(this));

    this.$element
      .querySelectorAll('[data-action]')
      .forEach($trigger => this.setAction($trigger));
    this.onscroll();
  }

  /**
   * Sets an action to its trigger element
   * @param {HTMLElement} $trigger The element which triggers a given action
   */
  setAction($trigger) {
    const { action } = $trigger.dataset;
    if (action && QuickLinks.QuicklinkActions[action]) {
      $trigger.addEventListener('click', QuickLinks.QuicklinkActions[action].bind(this));
      $trigger.addEventListener('click', this.closeMenu.bind(this));
    }
  }

  openMenu() {
    this.$container.classList.add(this.options.classes.containerOpenModifier);
    this.$container.setAttribute('aria-expanded', 'true');
    document.body.classList.add('scroll-locked');
  }

  closeMenu() {
    this.$container.classList.remove(this.options.classes.containerOpenModifier);
    this.$container.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('scroll-locked');
  }

  openItem($item) {
    $item.classList.add(this.options.classes.itemOpenModifier);
    $item.setAttribute('aria-expanded', 'true');
  }

  closeItem($item) {
    $item.classList.remove(this.options.classes.itemOpenModifier);
    $item.setAttribute('aria-expanded', 'false');
  }

  async showContainer() {
    clearTimeout(this.timer);
    this.$container.removeAttribute('style');
    return new Promise((resolve) => {
      this.timer = setTimeout(() => {
        this.$container.classList.remove(this.options.classes.containerHiddenModifier);
        resolve();
      }, 50);
    });
  }

  async hideContainer() {
    clearTimeout(this.timer);
    this.$container.classList.add(this.options.classes.containerHiddenModifier);
    return new Promise((resolve) => {
      this.timer = setTimeout(() => {
        this.$container.style.display = 'none';
        resolve();
      }, 300);
    });
  }

  toggle(event) {
    const controlledId = event.target.getAttribute('aria-controls');
    const $controlled = this.$element.querySelector(`#${controlledId}`);

    if ($controlled) {
      const opened = $controlled.getAttribute('aria-expanded') === 'true';

      if (opened) {
        this.closeItem($controlled);
      } else {
        this.openItem($controlled);
      }
    }
  }

  async onmediachange(mediaEvent) {
    this.desktopEnabled = mediaEvent.matches;
    if (this.desktopEnabled) {
      this.closeMenu();
      await this.onscroll();
      return;
    }
    this.showContainer();
  }

  async onscroll() {
    if (!this.showAlways && this.desktopEnabled && (
      document.body.clientHeight > window.innerHeight
      && window.scrollY < this.scrollOffset
    )) {
      await this.hideContainer();
      return;
    }
    await this.showContainer();
  }

  /**
   * Initializes all default quicklinks component
   * @param {any} options Optional parameters for the default initialization
   */
  static InitializeDefaults(options = {}) {
    const { element, initializedModifier } = QuickLinks.DefaultOptions.classes;
    const qryString = `.${element}:not(.${initializedModifier})`;

    document
      .querySelectorAll(qryString)
      .forEach($quicklinks => new QuickLinks($quicklinks, options));
  }

  /**
   * Register a quicklink action
   * @param {string} name
   * @param {(event: Event, ...params) => void} action The action callback to be registered
   */
  static RegisterQuicklinkAction(name, action) {
    QuickLinks.QuicklinkActions[name] = action;
  }
}

QuickLinks.RegisterQuicklinkAction('chatbot-open', chatBotOpen);

// Initialize quicklinks module with actions
QuickLinks.InitializeDefaults();
