// Quicklink action callbacks
export default function chatBotOpen(_, widgedId = 'botario-chat-widget') {
  const chatWidgetOpenModifier = `${widgedId}-float-open`;
  const $chatWidget = document.getElementById(widgedId);
  if ($chatWidget) {
    if ($chatWidget.classList.contains(chatWidgetOpenModifier)) {
      $chatWidget.classList.remove(chatWidgetOpenModifier);
      console.info('Chatbot widget closed'); // eslint-disable-line
    } else {
      $chatWidget.classList.add(chatWidgetOpenModifier);
      console.info('Chatbot widget opened'); // eslint-disable-line
    }
  }
}
