export default class TextInput {
  static DefaultOPtions = {
    classes: {
      element: 'js-textinput',
      initializedModifier: 'textinput--initialized',
    },
  };

  /**
   * Creates a new TextInput instance
   * @param {Element} $element The actual dom element
   * @param { {
   *  onblur?: (input: TextInput) => void,
   *  onfocus?: (input: TextInput) => void,
   *  onerror?: (input: TextInput) => void,
   *  onchange?: (input: TextInput) => void,
   *  classes: {
   *    inputField: string,
   *    errorMessage: string
   *  }
   * } } options Additional options
   */
  constructor($element, options = TextInput.DefaultOPtions) {
    this.$element = $element;
    this.options = {
      onblur: undefined,
      onfocus: undefined,
      onerror: undefined,
      onchange: undefined,
      ...options,
      classes: {
        inputField: 'textinput__input',
        errorMessage: 'textinput__error',
        ...(options.classes || {}),
      },
    };

    this.init()
      .then(() => {
        this.$element.classList.add(this.options.classes.initializedModifier);
      });
  }

  get value() {
    return this.$inputField.value;
  }

  async init() {
    this.$inputField = this.$element.querySelector(`.${this.options.classes.inputField}`);
    this.$errorMessage = this.$element.querySelector(`.${this.options.classes.errorMessage}`);

    if (this.$inputField) {
      this.$inputField.addEventListener('focus', this.focus.bind(this));
      this.$inputField.addEventListener('blur', this.blur.bind(this));
      this.$inputField.addEventListener('input', this.change.bind(this));
    }

    if (this.$errorMessage) {
      this.hideError();
    }
  }

  change() {
    if (this.options.onchange) {
      this.options.onchange(this);
    }
  }

  blur() {
    /**
     * @var { HTMLInputElement } $inputField
     */
    const { $inputField } = this;
    if ($inputField.validity && !$inputField.validity.valid) {
      this.showError();
      if (this.options.onerror) {
        this.options.onerror(this);
      }
      return;
    }

    this.hideError();

    if (this.options.onblur) {
      this.options.onblur(this);
    }
  }

  focus() {
    const { $inputField } = this;

    this.hideError();
    if (this.options.onfocus) {
      this.options.onfocus($inputField);
    }
  }

  showError() {
    this.$errorMessage.setAttribute('aria-hidden', 'false');
  }

  hideError() {
    this.$errorMessage.setAttribute('aria-hidden', 'true');
  }

  static InitializeDefaults() {
    const { element, initializedModifier } = TextInput.DefaultOPtions.classes;
    const qryString = `.${element}:not(.${initializedModifier})`;

    document
      .querySelectorAll(qryString)
      .forEach($textinput => new TextInput($textinput));
  }
}

TextInput.InitializeDefaults();
