export default class OfficeItem {
  /**
   * Creates a new Office item from markup
   * @param { Element } $element
   * @param { {
   *  uid: string,
   *  zipCode: string,
   *  longitude: number,
   *  latitude: number,
   *  distanceText: string,
   *  distance: number,
   *  classes: {
   *    element: string,
   *    initializedModifier,
   *    innerElement: string,
   *    title: string,
   *  },
   *  onopen: (item: OfficeItem) => void,
   *  onclose: (item: OfficeItem) => void
   * } } options
   */
  constructor($element, options = {}) {
    this.$element = $element;
    this.options = {
      onopen: null,
      onclose: null,
      uid: $element.dataset.uid || '',
      zipCode: $element.dataset.zipCode,
      longitude: parseFloat($element.dataset.lon || '0'),
      latitude: parseFloat($element.dataset.lat || '0'),
      distance: parseFloat($element.dataset.distance || '0'),
      ...options,
      classes: {
        title: 'office-item__title',
        innerElement: 'office-item__inner',
        ...(options.classes || { }),
      },
    };

    this.init();
  }

  get uid() {
    return this.options.uid;
  }

  get zipCode() {
    return this.options.zipCode;
  }

  /**
   * Gets the name of the office branch.
   * @returns string The name of the office branch
   */
  get name() {
    return this.$title.textContent;
  }

  /**
   * Sets the name of the office branch
   * @param {string} value The new title for the office branch
   */
  set name(value) {
    this.$title.innerHTML = value;
  }

  init() {
    this.$title = this.$element.querySelector(`.${this.options.classes.title}`);
    this.$innerElement = this.$element.querySelector(`.${this.options.classes.innerElement}`);

    this.$title.addEventListener('click', this.titleClick.bind(this));
  }

  titleClick() {
    if (!this.$innerElement.hasAttribute('open')) {
      if (this.options.onopen) {
        this.options.onopen(this);
      }
    } else if (this.options.onclose) {
      this.options.onclose();
    }
  }

  open() {
    this.$element.firstElementChild.setAttribute('open', '');
    this.$element.scrollIntoView();
  }

  close() {
    this.$element.firstElementChild.removeAttribute('open');
    if (this.options.onclose) {
      this.options.onclose(this);
    }
  }

  get Distance() {
    return parseFloat(this.$title.dataset.distance);
  }

  /**
   * @param {number} value
   */
  set Distance(value) {
    if (!value) {
      this.$title.removeAttribute('data-distance');
      return;
    }

    this.$title.dataset.distance = value;
  }

  static defaults = {
    uid: '%UID',
    name: '%NAME%',
    street: '%STREET%',
    zip: '%ZIP%',
    city: '%CITY%',
    times: '%TIMES%',
    phone: '%PHONE%',
    phone_time: '%PHONETIME%',
    fax: '07720 9727-0',
    email: 'kundencenter@vividabkk.de',
    latitude: '%LAT%',
    longitude: '%LON%',
    sickPay: '%SICKPAY%',
    care: '%CARE%',
    teeth: '07720 9727-55255',
    help: '07720 9727-55201',
    reha: '0981 9531-35451',
    homeCare: '0421 43551-65701',
    corpClients: '07720 9727-55501',
  }
}
