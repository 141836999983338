import * as focusTrap from 'focus-trap';

const classes = {
  body: 'header-search-active',
};

const $header = document.querySelector('.header');
const searchButtons = $header.querySelectorAll('.js-toggle-search[aria-expanded]');

searchButtons.forEach(($button) => {
  const controlsId = $button.getAttribute('aria-controls');
  const $controlsEl = document.querySelector(`#${controlsId}`);
  const $backButton = $controlsEl.querySelector('.search-bar__close');

  const showSearch = (show = true) => {
    $button.setAttribute('aria-expanded', `${show}`);
    $controlsEl.setAttribute('aria-hidden', `${!show}`);

    if (show) {
      document.body.classList.add(classes.body);
    } else {
      document.body.classList.remove(classes.body);
    }
  };

  const trap = focusTrap.createFocusTrap(`#${controlsId}`, {
    allowOutsideClick: true,
    clickOutsideDeactivates: (e) => e.target !== $button, // eslint-disable-line
    onDeactivate: () => showSearch(false),
  });

  $backButton.addEventListener('click', () => {
    showSearch(false);
    trap.deactivate();
  });

  $button.addEventListener('click', () => {
    const isExpanded = $button.getAttribute('aria-expanded') !== 'true'; // isExpanded defines the state after the click

    if (isExpanded) {
      showSearch();
      setTimeout(() => trap.activate(), 50);
    } else {
      showSearch(false);
      trap.deactivate();
    }
  });
});

// const $navigationToggle = document.body.querySelector('#navigation-main-toggle');

// $navigationToggle.addEventListener('click', () => {
//   document.body.classList.toggle('navigation-open');
// });
