import OfficeItem from '../../atoms/office-item/office-item';

export default class OfficeList {
  /**
   * Creates a new Office List instance.
   * @param {Element} $element The dom element to be anhanced
   * @param { { classes: {
   *  listItem: string,
   *  listItemHidden: string,
   *  listItemTemplate: string,
   *  listItemFilterPass: string,
   *  listItemFilterFail: string
   * } }} options Additional options to the instance
   */
  constructor($element, options = { }) {
    this.$element = $element;
    this.options = {
      ...options,
      classes: {
        list: 'office-list__items',
        listItem: 'office-list__item',
        listItemHidden: 'office-list__item--hidden',
        listItemTemplate: 'js-office-list__item-template',
        listItemFilterFail: 'office-list__item--filter-fail',
        listItemFilterPass: 'office-list__item--filter-pass',
        ...(options.classes || []),
      },
    };

    this.init();
  }

  init() {
    this.$list = this.$element.querySelector(`.${this.options.classes.list}`);
    this.listItems = this.$list
      ? Array.from(
        this.$list.querySelectorAll(`.${this.options.classes.listItem}`),
      ).map($item => ({ $item, officeItem: new OfficeItem($item) }))
      : [];

    this.$template = this.$element
      .querySelector(`.${this.options.classes.listItemTemplate}`);
  }

  /**
   * @param {HTMLLIElement} $listItem
   */
  filterPass($listItem) {
    $listItem.classList.add(this.options.classes.listItemFilterPass);
    $listItem.classList.remove(this.options.classes.listItemFilterFail);
  }

  /**
   * @param {HTMLLIElement} $listItem
   */
  filterFail($listItem) {
    $listItem.classList.remove(this.options.classes.listItemFilterPass);
    $listItem.classList.add(this.options.classes.listItemFilterFail);
  }

  /**
   * Filter function for the list
   * @param { (item: OfficeItem) => boolean } filterCallback
   * A callback function which is applied to each list item.
   */
  filter(filterCallback) {
    /**
     * @type {{$item: HTMLLIElement, officeItem: OfficeItem}[]}
     */
    const failed = [];
    const passed = this.listItems.filter(
      (listItem) => {
        const result = filterCallback(listItem.officeItem);
        if (!result) {
          failed.push(listItem);
        }
        return result;
      },
    );

    passed.forEach(item => this.filterPass(item.$item));
    failed.forEach(item => this.filterFail(item.$item));
    return { passed, failed };
  }

  /**
   * Add a new item to the list
   * @param {HTMLLIElement} $item The list item to be added
   * @param { any } itemOptions Additional options to the list item
   */
  addItem($item, itemOptions = { }) {
    if (this.$list) {
      // The actual office item is build from the first child of the list items li element
      this.listItems.push({
        $item,
        officeItem: new OfficeItem($item.firstElementChild, itemOptions),
      });
      // But the li element is mounted into the dom element
      this.$list.insertBefore($item, this.$template);
    }
  }

  /**
   * @param {{$item:HTMLLIElement,officeItem:OfficeItem}} item
   */
  removeItem(item) {
    const removeIndex = this.listItems.indexOf(item);
    this.listItems.splice(removeIndex, 1);
    this.$list.removeChild(item.$item);
  }

  clearItems() {
    this.$list
      .querySelectorAll(`.${this.options.classes.listItem}`)
      .forEach($item => this.$list.removeChild($item));
    this.listItems.length = 0;
  }

  /**
   * Sort the list items
   * @param {(
   *  item1: {$item: Element, officeItem: OfficeItem},
   *  item2: {$item: Element, officeItem: OfficeItem}) => number } sortCallback
   *  A callbackfunction to determine the sorting order.Returns `-1`, `0` or `1`;
   */
  sortItems(sortCallback) {
    this.listItems.sort(sortCallback);
    this.listItems.forEach(item => this.$list.insertBefore(item.$item, this.$template));
  }

  /**
   * Create a new office item an get its dom node
   * @param { {
   * uid: string,
   * name: string,
   * street: string,
   * zip: string,
   * city: string,
   * times: string,
   * phone: string,
   * phone_time: string,
   * fax: string,
   * email: string,
   * latitude: string|number
   * longitude: string|number,
   * sickPay: string,
   * care: string,
   * homeCare: string,
   * help: string,
   * reha: string,
   * teeth: string,
   * corpClients: string
   * } } options Options to create a new office item.
   * @returns {HTMLLIElement} A new office item.
   */
  static CreateItem(options = { }) {
    const itemOptions = {
      ...OfficeItem.defaults,
      ...options,
    };

    const $template = document
      .querySelector('.js-office-list__item-template')
      .cloneNode(true);

    const $li = document.createElement('li');
    $li.classList.add('office-list__item');

    if ($template) {
      const tplString = $template.innerHTML
        .replaceAll('%UID%', itemOptions.uid)
        .replaceAll('%ZIPCODE%', itemOptions.zip)
        .replaceAll('%TITLE%', itemOptions.name)
        .replaceAll('%CITY%', itemOptions.city)
        .replaceAll('%STREET%', itemOptions.street)
        .replaceAll('%TIMES%', itemOptions.times.replaceAll('\n', '<br/>'))
        .replaceAll('%PHONE%', itemOptions.phone)
        .replaceAll('%PHONETIME%', itemOptions.phone_time)
        .replaceAll('%FAX%', itemOptions.fax)
        .replaceAll('%EMAIL%', itemOptions.email)
        .replaceAll('%SICKPAY%', itemOptions.sickPay)
        .replaceAll('%CARE%', itemOptions.care)
        .replaceAll('%HELP%', itemOptions.help)
        .replaceAll('%TEETH%', itemOptions.teeth)
        .replaceAll('%REHA%', itemOptions.reha)
        .replaceAll('%HOMECARE%', itemOptions.homeCare)
        .replaceAll('%CORPCLIENTS%', itemOptions.corpClients)
        .replaceAll('%LON%', itemOptions.longitude)
        .replaceAll('%LAT%', itemOptions.latitude);

      $li.innerHTML = tplString;
    }

    return $li;
  }

  /**
   * Initializes a new office list
   * @param {string} apiEndPoint The api endpoint where the list of branch office is fetched from
   * @returns { OfficeList } A new instance of the OfficeList class
   */
  static GetInitialized(apiEndPoint = '/api/office-branches.json') {
    const $officeListElement = document.querySelector('.js-office-list');
    const officeList = new OfficeList($officeListElement);

    fetch(apiEndPoint)
      .then(response => response.json())
      .then((offices) => {
        Array.from(offices).forEach(
          office => officeList.addItem(
            OfficeList.CreateItem(office),
          ),
        );
      });

    return officeList;
  }
}
