export default class Chatbox {
  constructor($element, options = {}) {
    this.$element = $element;
    this.options = {
      classes: {
        chatbox: 'chatbox',
        button: 'chatbox__button',
        attentionMessage: 'chatbox__attention-message-container',
        chatboxHiddenModifier: 'chatbox--hidden',
        messageHiddenModifier: 'chatbox__attention-message-container--hidden',
        widget: 'botario-chat-widget',
        widgetOpenModifier: 'botario-chat-widget-float-open',
        initializedModifier: 'js-chatbox--initialized',
      },
      popupDelay: Number($element.dataset.popupDelay),
      ...options,
    };

    this.init();
  }

  init() {
    this.$button = this.$element.querySelector(`.${this.options.classes.button}`);
    this.$attentionMessage = this.$element.querySelector(`.${this.options.classes.attentionMessage}`);
    this.$widget = document.querySelector(`.${this.options.classes.widget}`);

    // Add eventlisteners
    this.$button.addEventListener('click', this.buttonClick.bind(this));
    this.$button.addEventListener('focus', () => this.showMessage());
    window.addEventListener('scroll', this.scroll.bind(this));

    this.hide();
    this.hideMessage();
    setTimeout(() => this.show(), this.options.popupDelay);
    setTimeout(() => this.showMessage(), this.options.popupDelay + 500);

    this.$element.classList.add(this.options.classes.initializedModifier);
  }

  hide() {
    this.$element.classList.add(this.options.classes.chatboxHiddenModifier);
  }

  show() {
    this.$element.classList.remove(this.options.classes.chatboxHiddenModifier);
  }

  hideMessage() {
    this.$attentionMessage.classList.add(this.options.classes.messageHiddenModifier);
  }

  showMessage() {
    this.$attentionMessage.classList.remove(this.options.classes.messageHiddenModifier);
  }

  buttonClick() {
    this.showWidget();
  }

  showWidget() {
    this.hideMessage();
    if (!this.$widget) {
      this.$widget = document.querySelector(`.${this.options.classes.widget}`);
    }

    this.$widget.classList.add(this.options.classes.widgetOpenModifier);
  }

  scroll() {
    this.hideMessage();
  }
}

document.querySelectorAll('.js-chatbox').forEach(
  $chatbox => new Chatbox($chatbox),
);
