import h from 'hyperscript';

const WHITESPACES = /^\s+|\s+$/g;

const SELECTORS = {
  base: '.typeahead',
  container: '.typeahead__container',
  list: 'ul.typeahead__list',
  listItem: 'li.typeahead__list-item',
};

class Typeahead {
  constructor($el) {
    this.$el = $el;
    this.id = this.$el.getAttribute('id');
    this.dataSource = this.$el.dataset.typeahead;

    this.init();
  }

  init() {
    this.initContainer();
    this.initEvents();
  }

  initContainer() {
    this.$typeahead = h(SELECTORS.base);
    this.append(this.$typeahead);
    this.$typeahead.appendChild(this.$el);
  }

  initEvents() {
    this.$el.addEventListener('input', () => {
      this.clearSuggestions();
      this.$el.setAttribute('data-typeahead-chosen', 'false');

      if (this.inputIsPresent() && this.isActive()) {
        this.getSuggestions(
          data => this.updateSuggestions(data),
          () => this.clearSuggestions(),
        );
      }
    });

    this.$el.addEventListener('keydown', (event) => {
      if (this.isExpanded()) {
        // Down
        if (event.keyCode === 40) {
          event.preventDefault();

          this.selectNextListItem();
        }

        // Up
        if (event.keyCode === 38) {
          event.preventDefault();

          this.selectPreviousListItem();
        }

        // Return
        if (event.keyCode === 13) {
          event.preventDefault();

          this.selectCurrentListItem();
        }

        // Tab
        if (event.keyCode === 9) {
          this.clearSuggestions();
        }
      }
    });

    document.addEventListener('keydown', (event) => {
      // Escape
      if (event.keyCode === 27) {
        event.preventDefault();

        this.clearSuggestions();
      }
    });

    window.addEventListener('click', (event) => {
      if (event.target !== this.$el) {
        this.clearSuggestions();
      }
    });
  }

  selectPreviousListItem() {
    const $currentSelectedItem = this.hasAriaSelectedItem();

    if ($currentSelectedItem) {
      if ($currentSelectedItem.previousSibling) {
        $currentSelectedItem.previousSibling.setAttribute('aria-selected', 'true');
        $currentSelectedItem.setAttribute('aria-selected', 'false');

        this.$typeheadContainer.scrollTop = $currentSelectedItem.previousSibling.offsetTop
        - this.$typeheadContainer.offsetTop;
      }
    } else {
      const $el = this.$typeahead.querySelector(`${SELECTORS.listItem}:last-child`);
      $el.setAttribute('aria-selected', 'true');
    }
  }

  selectNextListItem() {
    const $currentSelectedItem = this.hasAriaSelectedItem();

    if ($currentSelectedItem) {
      if ($currentSelectedItem.nextSibling) {
        $currentSelectedItem.nextSibling.setAttribute('aria-selected', 'true');
        $currentSelectedItem.setAttribute('aria-selected', 'false');

        this.$typeheadContainer.scrollTop = $currentSelectedItem.nextSibling.offsetTop
        - this.$typeheadContainer.offsetTop;
      }
    } else {
      const $el = this.$typeahead.querySelector(`${SELECTORS.listItem}:first-child`);
      $el.setAttribute('aria-selected', 'true');
    }
  }

  selectCurrentListItem() {
    const $currentSelectedItem = this.hasAriaSelectedItem();

    if ($currentSelectedItem) $currentSelectedItem.click();
  }

  initListBindings() {
    this.$typeahead.querySelectorAll(SELECTORS.listItem).forEach(($listItem) => {
      $listItem.addEventListener('click', (event) => {
        const listItem = event.target;
        const targetIds = listItem.dataset.targetIds.split(',');
        const targetValues = listItem.dataset.targetValues.split(',');

        targetIds.forEach((targetId, index) => {
          document.querySelector(`[data-typeahead-target-id="${targetId}"]`).value = targetValues[index];
        });

        this.$el.setAttribute('data-typeahead-chosen', 'true');

        this.clearSuggestions();
      });
    });
  }

  updateSuggestions(data) {
    this.clearSuggestions();

    if (data.length) {
      const suggestions = data.map(
        datum => h(SELECTORS.listItem, {
          attrs: {
            'data-target-ids': datum.fields.map(field => field.id).join(','),
            'data-target-values': datum.fields.map(field => field.value).join(','),
          },
        }, datum.display),
      );

      this.$typeheadContainer = h(SELECTORS.container, { attrs: { 'data-typeahead-id': this.id } },
        h(SELECTORS.list, suggestions));

      this.append(this.$typeheadContainer);

      this.initListBindings();
    }
  }

  clearSuggestions() {
    const $typeaheadContainer = document.querySelector(`[data-typeahead-id="${this.id}"]`);

    if (!$typeaheadContainer) {
      return;
    }

    $typeaheadContainer.remove();
  }

  sourceUrl() {
    return this.dataSource.includes('.json') ? this.dataSource : `${this.dataSource}/${this.$el.value}`;
  }

  isExpanded() {
    return this.$typeahead.querySelector(SELECTORS.list);
  }

  hasAriaSelectedItem() {
    return this.$typeahead.querySelector('[aria-selected="true"]');
  }

  inputIsPresent() {
    return this.$el.value.replace(WHITESPACES, '') !== '';
  }

  isActive() {
    const isActive = !(this.$el.dataset.typeaheadDisabled === 'true');
    return isActive;
  }

  getSuggestions(successCallback, errorCallback) {
    const req = new XMLHttpRequest();

    req.open('get', this.sourceUrl());

    req.onload = () => {
      if (req.status === 200) {
        successCallback(JSON.parse(req.response));
      } else {
        errorCallback(req.statusText);
        throw new Error(req.statusText);
      }
    };

    req.send();
  }

  append(element) {
    this.$el.parentNode.insertBefore(element, this.$el.nextSibling);
  }
}

document.querySelectorAll('[data-typeahead]').forEach(
  $el => new Typeahead($el),
);
