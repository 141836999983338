/**
 * @author Tobiq https://stackoverflow.com/questions/43575363/css-100vh-is-too-tall-on-mobile-due-to-browser-ui
 */
export default function safariresize() {
  document.body.height = window.innerHeight;
}

window.addEventListener('resize', safariresize);

safariresize();
